import { Component, Inject, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notifier-toast',
  imports: [ MatIconModule, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction ],
  templateUrl: './notifier-toast.component.html',
  styleUrl: './notifier-toast.component.css'
})
export class NotifierToastComponent {
  snackBarRef = inject(MatSnackBarRef)
  
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { type:string, message:string }){}
}
