import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifierToastComponent } from './notifier-toast.component';

enum NotifyType {
  error = 'error',
  success = 'success',
  warning = 'warning',
  info = 'info'
}

/**
 * Angular notifier options
 */
export const NOTIFIER_OPTIONS = {
  position: {
    horizontal: 'right',
    vertical:  'top'
  },
  behavior: {
    autoHide: 3000,
  }
};

@Injectable({
  providedIn: 'root'
})
export class NotifierService {
  private _snackBar = inject(MatSnackBar);

  notify(type: keyof typeof NotifyType, msg: string){
    this._snackBar.openFromComponent(NotifierToastComponent,{
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration:NOTIFIER_OPTIONS.behavior.autoHide,
      data: { type: type, message:msg }
    });
  }

  hideNewest(){
    this._snackBar.dismiss();
  }
}
