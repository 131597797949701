import { AuthConfig } from 'angular-oauth2-oidc';


// Mecanismo PKCE.
const authXiiberoPKCEConfig: AuthConfig = {
  // Url of the Identity Provider
  // URL of the SPA to redirect the user to after login
  //redirectUri: window.location.origin + '/dashboard',
  //redirectUri: 'https://oauth.pstmn.io/v1/callback',
  redirectUri: window.location.origin + '/public/welcome',//https://127.0.0.1:4200

  // URL of the SPA to redirect the user after silent refresh
  //silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  //silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,//window.location.origin + '/silent-refresh.html',//https://127.0.0.1:4200

  // The SPA's id. The SPA is registerd with this id at the auth-server
  //clientId: 'demo-resource-owner',
  //clientId: 'spa-app',
  clientId: 'spa-app',
  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile email authserver merchantsApi adminXiibero', // offline_access

  responseType: 'code',

  useSilentRefresh: false,
  //silentRefreshTimeout: 5000, // For faster testing
  timeoutFactor: 1, // For faster testing
  sessionChecksEnabled: true,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools

  clearHashAfterLogin: true,
  oidc: true, // si false no muestra idtoken.
  
/*
  useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  silentRefreshTimeout: 5000, // For faster testing
  timeoutFactor: 0.10, // For faster testing
  //timeoutFactor: 0.01,
  sessionChecksEnabled: true,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: true, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  //nonceStateSeparator : 'semicolon' // Real semicolon gets mangled by Duende ID Server's URI encoding
*/



};



  // Use HashLocationStrategy for routing?
export const useHash = false;

// Set this to true, to use silent refresh; otherwise the example
// uses the refresh_token via an AJAX coll to get new tokens.
export const useSilentRefreshForCodeFlow = false;


export const authSpaConfig: AuthConfig = authXiiberoPKCEConfig;

